import PhoneNumber from 'awesome-phonenumber'


const mixin = {
  data () {
    return {
      rules: {},
      validated: false,

      rule: {
        required: message => {
          return value => {
            message = message || this.$t('validation.required')
            if (Array.isArray(value)) {
              return value.length > 0 || message
            }
            return (!this.isFalseOrWhitespace(value)) || message
          }
        },
        sms: message => {
          return value => {
            message = message || this.$t('validation.invalidSms')
            return !value || !this.rule.maxLength(70, value) || message
          }
        },
        email: message => {
          return value => {
            message = message || this.$t('validation.invalidEmail')
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !value || pattern.test(value) || message
          }
        },
        url: message => {
          return value => {
            message = message || this.$t('validation.invalidUrl')
            const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi
            return !value || typeof (value) !== 'string' || pattern.test(value) || message
          }
        },
        phone: message => {
          return value => {
            message = message || this.$t('validation.invalidPhone')
            let valid = true

            if (value) {
              const phoneNumber = new PhoneNumber(value)
              const pattern = /^\+[0-9]+$/g
              valid = pattern.test(value) && phoneNumber.isValid() && phoneNumber.isMobile()
            }

            return valid || message
          }
        },
        maxLength: (max, message) => {
          return value => {
            if (!message && value) {
              message = Array.isArray(value) ? this.$t('validation.maxLengthArray', { max }) : this.$t('validation.maxLengthString', { max })
            }
            return !value || value.length <= max || message
          }
        },
        unique: (array, message) => {
          return value => {
            message = message || this.$t('validation.unique')
            return !value || !array.includes(value) || message
          }
        }
      }
    }
  },


  methods: {
    isFalseOrWhitespace (value) {
      if (typeof value === 'string') {
        return !value.trim()
      }
      if (value === 0) {
        return false
      }
      return !value
    },

    async validate (rules, formRef = 'form') {
      this.rules = rules

      await this.$nextTick()

      if (this.$refs[formRef]) {
        this.validated = true
        return this.$refs[formRef].validate()
      }

      return true
    },

    resetValidation (formRef = 'form') {
      this.rules = {}

      if (this.$refs[formRef]) {
        this.validated = false
        this.$refs[formRef].resetValidation()
      }
    },

    preventWhitespaceInput (event) {
      if (event.which === 32) {
        event.preventDefault()
        return false
      }
    },

    async scrollToFirstError () {
      await this.$nextTick()

      const errorElements = document.getElementsByClassName('error--text')
      if (errorElements.length) {
        errorElements[0].scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}


export default mixin


const mixin = {
  data () {
    return {
      watchedModel: null,
      initialWatchedModelJson: ''
    }
  },


  methods: {
    initWatchedModel (model) {
      this.watchedModel = model
      this.initialWatchedModelJson = JSON.stringify(this.watchedModel)
    },

    isModelChanged () {
      let result = false

      if (this.watchedModel) {
        result = JSON.stringify(this.watchedModel) !== this.initialWatchedModelJson
      }

      return result
    },

    async confirmEscape () {
      if (!this.isModelChanged()) {
        return true
      } else {
        return await this.$confirm(this.$t('components.confirm.unsavedChanges.message'), {
          trueText: this.$t('components.confirm.unsavedChanges.trueText'),
          falseText: this.$t('components.confirm.unsavedChanges.falseText')
        })
      }
    },

    async escape (onSuccess, onFailure) {
      const confirm = await this.confirmEscape()

      if (confirm) {
        if (onSuccess) onSuccess()
      } else {
        if (onFailure) onFailure()
      }

      return confirm
    }
  }
}


export default mixin
